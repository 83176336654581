import axios from 'axios';
import { toast } from 'react-toastify';
import { API_URL } from '../Constants'
import authHeader from "../services/auth-header";
import { useAuth } from '../hooks/useAuth';

const useAxiosInterceptor = () => {
  const { logout } = useAuth();

  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL || `${API_URL}api`,
    headers: authHeader()
  });

  axiosInstance.interceptors.response.use(
    response => response,
    error => {
      const errorMsg = error.response?.data?.message || error.message || 'An error occurred';
      
      if (error.response && error.response.status === 401) {
        // Handle unauthorized error (e.g., token expired)
        toast.error('Session expired. Please log in again. Logging out...', {
          position: "top-center",
          autoClose: 5000
        });
        // Redirect to login page after the toast is shown
        setTimeout(() => {
          logout(); // Use logout to clear the session
        }, 5000);
      }else{
        toast.error(errorMsg, {
          position: "top-center", // Change this to BOTTOM_CENTER if you prefer
          autoClose: 8000
      });
      }

      
      // Re-throw the error to prevent further processing
      return Promise.reject(error);
    }
  );
  return axiosInstance;
};

export default useAxiosInterceptor;