import { api } from "./AxiosConfig";
import { defineCancelApiObject } from "./AxiosUtils";


export const API = {
    getDepartments: async function(cancel = false) {
        const response = await api.request({
            url: `api/common/departments`,
            method: "GET",
            
            // retrieving the signal value by using the property name
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response;
    },
    addUser: async function(user, cancel = false){
        const response = await api.request({
            url: `api/admin/employee`,
            method: "POST",
            data: user,
            signal: cancel ? cancelApiObject[this.create.name].handleRequestCancellation().signal : undefined,
        })
        return response;
    }
}

const cancelApiObject = defineCancelApiObject(API)