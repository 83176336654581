import axios from "axios";
import { API_URL } from '../Constants'
import authHeader from "../services/auth-header";

export const api = axios.create({
  withCredentials: false,
  baseURL: API_URL,
  headers: authHeader() 
})
console.log(authHeader());
// defining a custom error handler for all APIs
const errorHandler = (error) => {
  const statusCode = error.response?.status

  // logging only errors that are not 401
  if (statusCode && statusCode !== 401) {
    console.error(error)
  }

  return Promise.reject(error)
}

// registering the custom error handler to the
// "api" axios instance
api.interceptors.response.use(undefined, (error) => {
  return errorHandler(error)
})